import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import QuestionSection from './components/QuestionSection';
import EraserTool from './components/EraserTool';
import DrawingCanvas from './components/DrawingCanvas';
import './App.css'; 

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [rating, setRating] = useState(1200);
  const [problems, setProblems] = useState([]);
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
  const [userRatings, setUserRatings] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [isEraserActive, setIseraserActive] = useState(false);
  const [clearResult, setClearResult] = useState(() => () => {});
  const toggleEraser = () => {
    setIseraserActive(!isEraserActive);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const handleLogin = async ({ username, password }) => {
    try {
      const response = await fetch('http://localhost:3000/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        setIsLoggedIn(true);
        setUsername(username);
        // No need to fetch problems here; let user choose problem type via buttons
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleRegister = async ({ username, password }) => {
    try {
      const response = await fetch('http://localhost:3000/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        alert('User registered');
      } else {
        alert('Registration failed');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const showProblem = () => {
    console.log("Current Problem Index", currentProblemIndex);
    console.log("Problems Array", problems);

    if (!problems.length) {
      console.error('Problems array is empty');
      return;
    }

    const problem = problems[currentProblemIndex];
    if (!problem) {
      console.error('Current problem is undefined');
      return;
    }

    if (!problem.question) {
      console.error('Problem question is undefined');
      return;
    }

    setStartTime(new Date().getTime());
  };

  const calculateELO = (userRating, problemRating, score, numProblemsSolved) => {
    const initialK = 100;
    const K = initialK / Math.sqrt(numProblemsSolved / 4 + 1);
    const expectedScore = 1 / (1 + Math.pow(10, (problemRating - userRating) / 400));
    const newRating = userRating + K * (score - expectedScore);
    return newRating;
  };

  const convertToNumber = (input) => {
    const numberWords = {
      "zero": 0, "one": 1, "two": 2, "three": 3, "four": 4, "five": 5,
      "six": 6, "seven": 7, "eight": 8, "nine": 9, "ten": 10,
      "eleven": 11, "twelve": 12, "thirteen": 13, "fourteen": 14,
      "fifteen": 15, "sixteen": 16, "seventeen": 17, "eighteen": 18, "nineteen": 19,
      "twenty": 20, "thirty": 30, "forty": 40, "fifty": 50,
      "sixty": 60, "seventy": 70, "eighty": 80, "ninety": 90,
      "hundred": 100
    };

    if (!isNaN(input)) {
      return parseInt(input, 10);
    }

    return numberWords[input] !== undefined ? numberWords[input] : null;
  };

  const updateRatings = async (updatedUserRatings) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      await fetch('http://localhost:3000/update-ratings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ ratings: updatedUserRatings, problemsSolved: updatedUserRatings.problemsSolved, times: updatedUserRatings.times })
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  const updateProblemRating = async (question, rating) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('http://localhost:3000/update-problem-ratings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ question, rating })
      });

      if (response.ok) {
        console.log('Problem rating updated successfully');
      } else {
        console.error('Failed to update problem rating');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  const handleSubmitAnswer = async (answer, setResult) => {
    const userAnswer = answer.trim().toLowerCase();
  
    if (userAnswer === "") {
      setResult("Please enter an answer");
      return;
    }
  
    const endTime = new Date().getTime();
    const timeTaken = (endTime - startTime) / 1000;
  
    const problem = problems[currentProblemIndex];
    const numericAnswer = convertToNumber(userAnswer);
  
    if (numericAnswer === null) {
      setResult("Please enter a valid number.");
      return;
    }
  
    let score = 0;
  
    if (numericAnswer === problem.answer) {
      score = 1;
      setResult("Correct!");
    } else {
      setResult("Incorrect. Try again!");
    }
  
    const updatedUserRatings = { ...userRatings };
    const userTypeRating = updatedUserRatings[problem.type];
    updatedUserRatings[problem.type] = calculateELO(userTypeRating, problem.rating, score, updatedUserRatings.problemsSolved);
    problem.rating = calculateELO(problem.rating, userTypeRating, 1 - score, updatedUserRatings.problemsSolved);
  
    updatedUserRatings.overall = (updatedUserRatings.addition + updatedUserRatings.subtraction + updatedUserRatings.multiplication + updatedUserRatings.division) / 4;
    setUserRatings(updatedUserRatings);
  
    await updateRatings(updatedUserRatings);
    await updateProblemRating(problem.question, problem.rating);
  
    setTimeout(() => {
      if (numericAnswer === problem.answer) {
        generateProblem(problem.type); // Generate a new problem of the same type
        setResult(''); // Clear the result
      }
    }, 2200);
  };
  
  const generateProblem = (type) => {
    let problem;
    switch (type) {
      case 'singleDigitAddition':
        problem = generateSingleDigitAddition();
        break;
      case 'singleDigitSubtraction':
        problem = generateSingleDigitSubtraction();
        break;
      case 'doubleDigitAdditionNoCarry':
        problem = generateDoubleDigitAdditionNoCarry();
        break;
      case 'doubleDigitSubtractionNoBorrow':
        problem = generateDoubleDigitSubtractionNoBorrow();
        break;
      case 'roundTwoDigitAddition':
        problem = generateRoundTwoDigitAddition();
        break;
      case 'roundTwoDigitSubtraction':
        problem = generateRoundTwoDigitSubtraction();
        break;
      case 'additionWithCarry':
        problem = generateAdditionWithCarry();
        break;
      case 'subtractionWithBorrow':
        problem = generateSubtractionWithBorrow();
        break;
      case 'singleDigitDoubles':
        problem = generateSingleDigitDoubles();
        break;
      case 'twoDigitDoubles' :
        problem = generateTwoDigitDoubles();
        break;
      case 'singleDigitTriples' :
        problem = generateSingleDigitTriples();
        break;
      case 'singleDigitQuads' :
        problem = generateSingleDigitQuads();
        break;
      default:
        console.error('Invalid problem type:', type);
        return;
    }
    setProblems([problem]);
    setCurrentProblemIndex(0); // Reset to the first problem
  };
  
  

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUsername('');
    setRating(1200);
    setProblems([]);
    setCurrentProblemIndex(0);
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <>
          <div className="logout-button interactive" onClick={handleLogout}>
            Logout
          </div>
          <div id="problem-type-container" className="interactive">
          <button onClick={() => generateProblem('singleDigitAddition')}>Single Digit Addition</button>
          <button onClick={() => generateProblem('singleDigitSubtraction')}>Single Digit Subtraction</button>
          <button onClick={() => generateProblem('doubleDigitAdditionNoCarry')}>Double Digit Addition (No Carry)</button>
          <button onClick={() => generateProblem('doubleDigitSubtractionNoBorrow')}>Double Digit Subtraction (No Borrow)</button>
          <button onClick={() => generateProblem('roundTwoDigitAddition')}>Round Two Digit Addition</button>
          <button onClick={() => generateProblem('roundTwoDigitSubtraction')}>Round Two Digit Subtraction</button>
          <button onClick={() => generateProblem('additionWithCarry')}>Addition With Carry</button>
          <button onClick={() => generateProblem('subtractionWithBorrow')}>Subtraction With Borrow</button>
          <button onClick={() => generateProblem('singleDigitDoubles')}> Single Digit Doubles</button>
          <button onClick={() => generateProblem('twoDigitDoubles')}> Two Digit Doubles</button>
          <button onClick={() => generateProblem('singleDigitTriples')}> Single Digit Triples </button>
          <button onClick={() => generateProblem('singleDigitQuads')}> Single Digit Quads </button>

           </div>


          {problems.length > 0 ? (
            <QuestionSection
              question={problems[currentProblemIndex]?.question}
              onSubmitAnswer={handleSubmitAnswer}
              clearResult={setClearResult}
            />
          ) : (
            <div>No problems available</div>
          )}
          <EraserTool isActive={isEraserActive} toggleEraser={toggleEraser} />
          <DrawingCanvas
            isLoggedIn={isLoggedIn}
            problems={problems}
            currentProblemIndex={currentProblemIndex}
            setCurrentProblemIndex={setCurrentProblemIndex}
            showProblem={() => {
              const index = (currentProblemIndex + 1) % problems.length;
              setCurrentProblemIndex(index);
            }}
            fetchProblems={generateProblem}
            userRatings={userRatings}
            setUserRatings={setUserRatings}
            setProblems={setProblems}
            isEraserActive={isEraserActive} // Pass the eraser state
          />
        </>
      ) : (
        <>
          <Header />
          <LoginForm onLogin={handleLogin} />
          <RegisterForm onRegister={handleRegister} />
        </>
      )}
    </div>
  );
}

// Problem generation functions
const generateSingleDigitAddition = () => {
  const num1 = Math.floor(Math.random() * 10);
  const num2 = Math.floor(Math.random() * 10);
  return {
    question: `${num1} + ${num2}`,
    answer: num1 + num2,
    type: 'singleDigitAddition'
  };
};

const generateSingleDigitSubtraction = () => {
  const num1 = Math.floor(Math.random() * 10);
  const num2 = Math.floor(Math.random() * 10);
  // Ensure no negative results
  const [larger, smaller] = num1 >= num2 ? [num1, num2] : [num2, num1];
  return {
    question: `${larger} - ${smaller}`,
    answer: larger - smaller,
    type: 'singleDigitSubtraction'
  };
};

const generateDoubleDigitAdditionNoCarry = () => {
  let num1, num2;
  do {
    num1 = Math.floor(Math.random() * 90) + 10; // Ensure two-digit number
    num2 = Math.floor(Math.random() * 90) + 10; // Ensure two-digit number
  } while ((num1 % 10 + num2 % 10) >= 10 || (num1 + num2) >= 100); // Ensure no carry and result under 100
  return {
    question: `${num1} + ${num2}`,
    answer: num1 + num2,
    type: 'doubleDigitAdditionNoCarry'
  };
};


const generateDoubleDigitSubtractionNoBorrow = () => {
  let num1, num2;
  do {
    num1 = Math.floor(Math.random() * 90) + 10; // Ensure two-digit number
    num2 = Math.floor(Math.random() * (num1 - 10)) + 10; // Ensure num2 is less than num1
  } while ((num1 % 10 - num2 % 10) < 0); // Ensure no borrow
  return {
    question: `${num1} - ${num2}`,
    answer: num1 - num2,
    type: 'doubleDigitSubtractionNoBorrow'
  };
};


const generateRoundTwoDigitAddition = () => {
  const num1 = Math.floor(Math.random() * 9) * 10;
  const num2 = Math.floor(Math.random() * 9) * 10;
  return {
    question: `${num1} + ${num2}`,
    answer: num1 + num2,
    type: 'roundTwoDigitAddition'
  };
};

const generateRoundTwoDigitSubtraction = () => {
  const num1 = Math.floor(Math.random() * 9) * 10;
  const num2 = Math.floor(Math.random() * 9) * 10;
  // Ensure no negative results
  const [larger, smaller] = num1 >= num2 ? [num1, num2] : [num2, num1];
  return {
    question: `${larger} - ${smaller}`,
    answer: larger - smaller,
    type: 'roundTwoDigitSubtraction'
  };
};

const generateAdditionWithCarry = () => {
  let num1, num2;
  const isSinglePlusDouble = Math.random() < 0.5;

  if (isSinglePlusDouble) {
    // Single-digit plus double-digit that requires carry
    do {
      num1 = Math.floor(Math.random() * 9) + 1; // 1 to 9
      num2 = Math.floor(Math.random() * 90) + 10; // 10 to 99
    } while ((num1 + num2) < 10 || (num1 % 10 + num2 % 10) < 10); // Ensure carry but keep the result below 100
  } else {
    // Double-digit plus double-digit that requires carry
    do {
      num1 = Math.floor(Math.random() * 90) + 10; // 10 to 99
      num2 = Math.floor(Math.random() * 90) + 10; // 10 to 99
    } while ((num1 % 10 + num2 % 10) < 10 || (num1 + num2) >= 100); // Ensure carry and result below 100
  }

  return {
    question: `${num1} + ${num2}`,
    answer: num1 + num2,
    type: 'additionWithCarry'
  };
};

const generateSubtractionWithBorrow = () => {
  let num1, num2;
  let attempts = 0;

  // Two-digit subtraction that requires borrow
  do {
    num1 = Math.floor(Math.random() * 90) + 10; // 10 to 99
    num2 = Math.floor(Math.random() * 90) + 10; // 10 to 99
    attempts++;
    if (attempts > 1000) break; // Prevent infinite loop
  } while ((num1 % 10 >= num2 % 10) || num1 <= num2); // Ensure borrow and no negative result

  return {
    question: `${num1} - ${num2}`,
    answer: num1 - num2,
    type: 'subtractionWithBorrow'
  };
};

const generateSingleDigitDoubles = () => {
  const num1 = Math.floor(Math.random() * 10);
  const num2 = 2;
  return {
    question: `${num1} * ${num2}`,
    answer: num1 * num2,
    type: 'singleDigitDoubles'
  };
};

const generateTwoDigitDoubles = () => {
  const num1 = Math.floor(Math.random() * 50) + 1; 
  const num2 = 2;
  return {
    question: `${num1} x ${num2}`,
    answer: num1 * num2,
    type: 'twoDigitDoubles'
  };
};

const generateSingleDigitTriples = () => {
  const num1 = Math.floor(Math.random() * 10);
  const num2 = 3;
  return {
    question: `${num1} * ${num2}`,
    answer: num1 * num2,
    type: 'singleDigitTriples'
  };
};

const generateSingleDigitQuads = () => {
  const num1 = Math.floor(Math.random() * 10);
  const num2 = 4;
  return {
    question: `${num1} * ${num2}`,
    answer: num1 * num2,
    type: 'singleDigitQuads'
  };
};


export default App;
