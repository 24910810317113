import React, { useEffect } from 'react';
import '../App.css';
import '../DrawingCanvas.css';

const DrawingCanvas = ({
  isLoggedIn,
  problems,
  currentProblemIndex,
  setCurrentProblemIndex,
  showProblem,
  fetchProblems,
  userRatings,
  setUserRatings,
  setProblems,
  isEraserActive // Adding isEraserActive prop
}) => {
  useEffect(() => {
    if (isLoggedIn) {
      fetchProblems();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const savedProblemIndex = localStorage.getItem('currentProblemIndex');
    const savedProblems = localStorage.getItem('problems');
    const savedUserRatings = localStorage.getItem('userRatings');

    if (savedProblemIndex !== null) {
      setCurrentProblemIndex(parseInt(savedProblemIndex, 10));
    }

    if (savedProblems) {
      setProblems(JSON.parse(savedProblems));
    }

    if (savedUserRatings) {
      setUserRatings(JSON.parse(savedUserRatings));
    }

    if (problems.length > 0) {
      showProblem();
    }
  }, []);

  useEffect(() => {
    const canvas = document.getElementById('drawing-canvas');
    const ctx = canvas.getContext('2d');
    let drawing = false;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const startDrawing = (e) => {
      if (!isLoggedIn) return;
      drawing = true;
      draw(e);
    };

    const endDrawing = () => {
      if (!isLoggedIn) return;
      drawing = false;
      ctx.beginPath();
    };

    const draw = (e) => {
      if (!drawing || !isLoggedIn) return;
      e.preventDefault();

      let clientX, clientY;

      if (e.touches) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else {
        clientX = e.clientX;
        clientY = e.clientY;
      }

      ctx.lineWidth = isEraserActive ? 10 : 5; // Change the line width for drawing (5) and erasing (10)
      ctx.lineCap = 'round';
      ctx.strokeStyle = isEraserActive ? 'rgba(0,0,0,0)' : '#f5f5f5'; // Change color based on eraser state

      ctx.globalCompositeOperation = 'source-over'; // Enable drawing and erasing

      ctx.lineTo(clientX, clientY);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(clientX, clientY);
    };

    const checkInteractiveElement = (e) => {
      const elements = document.elementsFromPoint(e.clientX, e.clientY);
      return elements.some((element) => element.classList.contains('interactive'));
    };

    canvas.addEventListener('mousedown', (e) => {
      if (!checkInteractiveElement(e)) {
        startDrawing(e);
      }
    });

    canvas.addEventListener('mouseup', endDrawing);
    canvas.addEventListener('mousemove', draw);

    canvas.addEventListener('touchstart', (e) => {
      if (!checkInteractiveElement(e.touches[0])) {
        startDrawing(e);
      }
    });

    canvas.addEventListener('touchend', endDrawing);
    canvas.addEventListener('touchmove', draw);

    document.querySelectorAll('.interactive').forEach((element) => {
      element.addEventListener('mousedown', (e) => e.stopPropagation());
      element.addEventListener('touchstart', (e) => e.stopPropagation());
    });

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mouseup', endDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchend', endDrawing);
      canvas.removeEventListener('touchmove', draw);
    };
  }, [isLoggedIn, isEraserActive]); // Add isEraserActive as a dependency

  return <canvas id="drawing-canvas" />;
};

export default DrawingCanvas;


