import React from 'react';

function EraserTool({ isActive, toggleEraser }) {
  return (
    <div id="eraser-tool" onClick={toggleEraser} style={{
      width: '50px',
      height: '50px',
      backgroundColor: isActive ? 'red' : 'grey', // Change color based on state
      position: 'absolute',
      top: '20px',
      right: '150px',
      cursor: 'pointer',
      zIndex: 10,
      pointerEvents: 'auto'
    }}>
     
    </div>
  );
}

export default EraserTool;
