import React, { useState, useEffect } from 'react';
import '../App.css';
import buttonBackground from '../images/green-chalk-2.jpg';

function QuestionSection({ question, onSubmitAnswer, clearResult }) {
  const [answer, setAnswer] = useState('');
  const [result, setResult] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const numericAnswer = convertToNumber(answer);

    if (numericAnswer === null) {
      setResult('Please enter a valid number.');
    } else {
      onSubmitAnswer(answer, setResult);
    }

    setAnswer('');
  };

  // Clear result when a new question is shown
  useEffect(() => {
    if (clearResult) {
      clearResult(() => setResult(''));
    }
  }, [question, clearResult]);
  
  return (
    <div id="question-container" className="interactive">
      <form onSubmit={handleSubmit} id="answer-form" className="interactive">
        <div className="input-group">
          <div id="question" className="interactive">{question}</div>
          <input
            type="text"
            id="answer"
            className="interactive"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder=" "
          />
        </div>
        <div id="submit-container" className="submit-group">
          <button 
            type="submit" 
            id="submit-button" 
            className="interactive" 
          >
            Submit
          </button>
          <div id="result" className={result === 'Correct!' ? 'animation-correct interactive' : 'interactive'}>
            {result}
          </div>
        </div>
      </form>
    </div>
  );
}

export default QuestionSection;

function convertToNumber(input) {
  const numberWords = {
    "zero": 0, "one": 1, "two": 2, "three": 3, "four": 4, "five": 5,
    "six": 6, "seven": 7, "eight": 8, "nine": 9, "ten": 10,
    "eleven": 11, "twelve": 12, "thirteen": 13, "fourteen": 14,
    "fifteen": 15, "sixteen": 16, "seventeen": 17, "eighteen": 18, "nineteen": 19,
    "twenty": 20, "thirty": 30, "forty": 40, "fifty": 50,
    "sixty": 60, "seventy": 70, "eighty": 80, "ninety": 90,
    "hundred": 100
  };

  if (!isNaN(input)) {
    return parseInt(input, 10);
  }

  return numberWords[input] !== undefined ? numberWords[input] : null;
}
